<template>
  <div class="evaluation_report">
    <navigationBar :showBack="showBack" :back="back">{{ name }}</navigationBar>
    <reportItem ref="reportItem"></reportItem>
    <van-popup v-model="showScore" :close-on-click-overlay="clickFalg"><score @handelClose="handelClose" @getEvaluationReport="getEvaluationReport" :code="code"></score> </van-popup>
  </div>
</template>

<script type="text/ecmascript-6">
import navigationBar from "@/components/navigationBar/navigationBar";
import reportItem from "@/components/reportItemToken/reportItem";
import score from "@/components/score/score";

export default {
  data() {
    return {
      showBack: false,
      name: "",
      back: 0,
      showScore: false,
      clickFalg: false,
      code: 0
    };
  },
  components: {
    navigationBar,
    reportItem,
    score
  },
  created() {
    this.name = JSON.parse(localStorage.getItem("name"));
    this.back = this.$route.query.back;
    this.code = this.$route.query.code;
  },
  mounted() {},
  methods: {
    handelClose(res) {
      this.showScore = res;
    },
    handelOpen() {
      this.showScore = true;
    },
    getEvaluationReport() {
      this.$refs.reportItem.getMoreData();
    }
  },
};
</script>

<style scoped lang="less">
.evaluation_report {
  margin-top: 0.88rem;
  background-color: #f3f3f3;
  padding: 0.2rem;
  min-height: calc(100vh - 0.88rem);
}

/deep/ .van-popup {
  background-color: transparent;
  border-radius: 0.1rem;
}
</style>
