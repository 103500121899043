<template>
  <div class="report_item">
    <div class="item" v-for="(item, index1) of tabInfos" :key="index1">
      <div class="table" v-if="index1 == 0">
        <div class="row">
          <div>姓名</div>
          <div>{{userInfo.realName}}</div>
        </div>
        <div class="row">
          <div>性别</div>
          <div>{{userInfo.sex}}</div>
        </div>
        <div class="row">
          <div>身份证号</div>
          <div>{{userInfo.idCard}}</div>
        </div>
        <div class="row">
          <div>手机号</div>
          <div>{{userInfo.phoneNum}}</div>
        </div>
        <div class="row">
          <div>测评机构</div>
          <div>启创未来（北京）科技有限公司</div>
        </div>
        <div class="row">
          <div>测评时间</div>
          <div>{{userInfo.createDateStr}}</div>
        </div>
      </div>
      <div class="title">{{ item.tabValue }}</div>
      <div
        class="text"
        v-for="(children, index) of item.dataShows"
        :key="index"
      >
        <!-- 图标-表格 -->
        <div
          v-if="children.dataType == 1 && children.data.chartType == 6"
          style="margin: 0.2rem 0"
        >
          <div
            class="row"
            v-for="(grandson, index) of children.data.chartData.data"
            :key="index"
          >
            <div>{{ grandson.lineDatas[0] }}</div>
            <div>{{ grandson.lineDatas[1] }}</div>
          </div>
        </div>
        <!-- 文字表述 -->
        <div v-if="children.dataType == 2">
          <p v-for="(grandson, index) of children.data" :key="index">
            {{ grandson }}
          </p>
        </div>
        <!-- 表述 -->
        <div v-if="children.dataType == 3">
          <div v-for="(grandson, index) of children.data" :key="index">
            <h4 style="font-size: 0.32rem; font-weight: 600">
              {{ grandson.title }}
            </h4>
          </div>
        </div>
        <!-- 标题文字表述 -->
        <div v-if="children.dataType == 4">
          <div v-for="(grandson, index) of children.data" :key="index">
            <h4 style="font-size: 0.32rem; font-weight: 600">
              {{ grandson.title }}
            </h4>
            <p
              style="text-indent: 2em"
              v-for="(i, index) of grandson.descriptions"
              :key="index"
            >
              {{ i }}
            </p>
          </div>
        </div>
        <!-- 图标-雷达图 -->
        <div v-if="children.dataType == 1 && children.data.chartType == 1">
          <div
            :id="'echarts' + index1"
            :style="{ width: '100%', height: '7rem' }"
            style="margin: 0 auto"
          ></div>
        </div>
        <!-- 图标-柱状图 -->
        <div v-if="children.dataType == 1 && children.data.chartType == 2">
          <div
            :id="'echarts' + index1"
            :style="{ width: '100%', height: '7rem' }"
            style="margin: 0 auto"
          ></div>
        </div>
        <!-- 图标-横向柱状图 -->
        <div v-if="children.dataType == 1 && children.data.chartType == 3">
          <div
            :id="'echarts' + index1"
            :style="{ width: '100%', height: '7rem' }"
            style="margin: 0 auto"
          ></div>
        </div>
        <!-- 图标-饼状图 -->
        <div v-if="children.dataType == 1 && children.data.chartType == 4">
          <div
            :id="'echarts' + index1"
            :style="{ width: '100%', height: '7rem' }"
            style="margin: 0 auto"
          ></div>
        </div>
      </div>
    </div>
    <div class="more" @click="handelMore()" v-show="showMore">查看更多>></div>
  </div>
</template>

<script type="text/ecmascript-6">
import { getEvaluationReport, getLoginToken, getReport} from "@/request/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      loginUsers: {},
      code: 0,
      userId: 0,
      tabInfos: {},
      showMore: true,
      token: '',
      key: '',
      userInfo: {}
    };
  },
  components: {},
  created() {
    this.key = this.$route.query.token;
    this.loginUsers = JSON.parse(localStorage.getItem("loginUser"));
    if(this.loginUsers) {
      this.userId = this.loginUsers.id;
    }
    this.code = this.$route.query.code;
  },
  mounted() {
    if(this.key) {
       this.getReport(this.key)
    }
   
  },
  methods: {
    ...mapMutations(["changeLogin", "loginUser"]),
    getLoginToken(param) {
      getLoginToken(param).then((res) => {
        console.log(res);
        if(res.token) {
          this.changeLogin(res.token);
          this.loginUser(JSON.stringify(res.loginUser));
          this.getEvaluationReport(this.userId, this.code);
          this.loginUsers = JSON.parse(localStorage.getItem("loginUser"));
          this.userId = this.loginUsers.id;
          this.code = this.$route.query.code;
        }
      });
    },

    getReport(res) {
      getReport(res).then((res) => {
        console.log(res)
        this.userInfo = res.data;
        this.$emit('getUserInfo', this.userInfo)
        this.tabInfos = res.data.tabInfos;
        if (res.data.score == 0) {
          if (res.data.tabInfos.length <= 2) {
            this.handelMore();
            this.showMore = false;
            this.processingData();
          } else {
            this.showMore = true;
            this.tabInfos = res.data.tabInfos.slice(0, 2);
            this.processingData();
          }
        } else {
          this.showMore = false;
          this.tabInfos = res.data.tabInfos;
          this.processingData();
        }
      })
    },

    getEvaluationReport(userId, code) {
      let param = {
        id: userId,
        code: code,
      };
      getEvaluationReport(param).then((res) => {
        this.tabInfos = res.data.tabInfos;
        if (res.data.score == 0) {
          if (res.data.tabInfos.length <= 2) {
            this.handelMore();
            this.showMore = false;
            this.processingData();
          } else {
            this.showMore = true;
            this.tabInfos = res.data.tabInfos.slice(0, 2);
            this.processingData();
          }
        } else {
          this.showMore = false;
          this.tabInfos = res.data.tabInfos;
          this.processingData();
        }
      });
    },

    // 处理各种图标数据
    processingData() {
      this.tabInfos.forEach((item, index) => {
        item.dataShows.forEach((cItem) => {
          if (cItem.dataType == 1 && cItem.data.chartType == 1) {
            this.$nextTick(() => {
              this.renderRadar("echarts" + index, cItem.data.chartData);
            });
          } else if (cItem.dataType == 1 && cItem.data.chartType == 2) {
            this.$nextTick(() => {
              this.renderVerticalColumn(
                "echarts" + index,
                cItem.data.chartData
              );
            });
          } else if (cItem.dataType == 1 && cItem.data.chartType == 3) {
            this.$nextTick(() => {
              this.renderTransverseColumn(
                "echarts" + index,
                cItem.data.chartData
              );
            });
          } else if (cItem.dataType == 1 && cItem.data.chartType == 4) {
            this.$nextTick(() => {
              this.renderCakeColumn("echarts" + index, cItem.data.chartData);
            });
          }
        });
      });
    },

    // 渲染雷达
    renderRadar(dom, data) {
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById(dom));

      // 绘制图表
      myChart.setOption({
        title: {},
        legend: {
          // data: ["预算分配（Allocated Budget）"],
          data: data.legendData,
        },
        radar: {
          // shape: 'circle',
          indicator: data.indicator,
        },
        series: [
          {
            name: "预算 vs 开销（Budget vs spending）",
            type: "radar",
            data: data.seriesData,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value;
                },
              },
            },
          },
        ],
      });
    },

    // 渲染竖向柱状图
    renderVerticalColumn(dom, data) {
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById(dom));

      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "category",
          data: data.xaxisData,
          axisLabel: {
            interval: 0, //代表显示所有x轴标签显示
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: data.seriesData,
            type: "bar",
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 14,
                  },
                },
              },
            },
          },
        ],
      });
    },

    // 渲染横向柱状图
    renderTransverseColumn(dom, data) {
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById(dom));

      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "value",
          data: data.xaxisData,
          axisLabel: {
            interval: 0, //代表显示所有x轴标签显示
          },
        },
        yAxis: {
          type: "category",
        },
        series: [
          {
            data: data.seriesData,
            type: "bar",
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 14,
                  },
                },
              },
            },
          },
        ],
      });
    },

    // 渲染饼柱状图
    renderCakeColumn(dom, data) {
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById(dom));

      // 绘制图表
      myChart.setOption({
        title: {
          left: "center",
        },
        tooltip: {
          trigger: "item",
          show: false,
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "50%",
            data: data.seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },

    // 更多
    handelMore() {
      this.$parent.handelOpen();
    },

    // 获取更多
    getMoreData() {
      this.getEvaluationReport(this.userId, this.code);
    }
  },
};
</script>

<style scoped lang="less">
.report_item {
  .item {
    padding: 0.4rem 0.2rem 0.3rem 0.2rem;
    background-color: #ffffff;
    border-radius: 0.12rem;
    margin-bottom: 0.2rem;

    &:last-child {
      margin: 0;
    }

    .title {
      font-size: 0.28rem;
      font-family: "PingFangSC-Regular";
      font-weight: 600;
      color: #ffffff;
      padding: 0.2rem 0.3rem;
      background: #0067b0;
      border-radius: 0.45rem;
      margin-bottom: 0.3rem;
      display: inline-block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);

      &:after {
        content: "";
        width: 0.4rem;
        height: 0.58rem;
        background-image: url("../../assets/images/title_left.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: -0.6rem;
        top: 50%;
        transform: translateY(-50%);
      }

      &:before {
        content: "";
        width: 0.4rem;
        height: 0.58rem;
        background-image: url("../../assets/images/title_right.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: -0.6rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .text {
      font-size: 0.28rem;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: #333333;
      line-height: 0.46rem;

      .row {
        display: flex;
        border-top: 0.01rem solid #333333;
        border-left: 0.01rem solid #333333;
        border-right: 0.01rem solid #333333;

        &:last-child {
          border-bottom: 0.01rem solid #333333;
        }

        div:first-child {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 0.01rem solid #333333;
        }

        div:last-child {
          flex: 2;
          padding: 0.1rem;
        }
      }
    }
  }

  .more {
    font-size: 0.28rem;
    font-family: "PingFangSC-Regular";
    font-weight: 600;
    color: #ffffff;
    padding: 0.2rem 0.3rem;
    background: #0067b0;
    border-radius: 0.45rem;
    margin: 0.4rem 0 0 0;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.table {
  border: 0.01rem solid #ccc;
  background-color: #fff;
  margin-bottom: 0.4rem;

  .row {
    display: flex;
    justify-content: space-between;
    padding: 0 0.1rem 0 0.1rem;
    border-bottom: 0.01rem solid #ccc;
    height: 0.7rem;
    line-height: 0.7rem;

    div {
      font-size: 0.28rem;
      color: #666666;

      &:first-child {
        border-right: 0.01rem solid #ccc;
         width: 20%;
      }

      &:last-child {
          padding-left: 10px;
         width: 80%;
         color: #333333;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
